import './App.css';
import Scene from './Scene';

function App() {
  return (
    <div className="App">
      <Scene/>
    </div>
  );
}

export default App;
